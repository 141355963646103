import { FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import Select from "react-select";
import { SelectType } from "_components";

export type CustomSelectProps = {
  // shared
  name: string;
  optionsList: Array<OptionSelect>;
  isMulti?: boolean;
  isLoading?: boolean;
  placeHolder?: string;
  isDisabled?: boolean;
  isSearchable?: boolean;
  noOptionsMessage?: string;
  type: SelectType;
  // native
  values?: Array<OptionSelect> | OptionSelect;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  required: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
};

export type OptionSelect = {
  label: string;
  value: string;
  name: string;
};

const CustomSelect: FunctionComponent<CustomSelectProps> = (props) => {
  const regularStyles = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (base: any) => ({
      ...base,
      border: "1px solid #263238",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #263238",
      },
      minHeight: 44,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menuList: (base: any) => ({
      ...base,
      border: "1px solid #263238",
      borderRadius: "4px",
      boxShadow: "none",
      paddingTop: 0,
      paddingBottom: 0,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menu: (base: any) => ({
      ...base,
      background: "none",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: "white",
      color: state.isSelected ? "#7D93AD" : "#263238",
      paddingTop: "18px",
      cursor: "pointer",
    }),
  };

  const darkSmallStyles = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (base: any) => ({
      ...base,
      border: "1px solid #829198",
      boxShadow: "none",
      backgroundColor: "#263238",
      color: "#fff",
      minHeight: "33px",
      "&:hover": {
        border: "1px solid #829198",
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    input: (base: any) => ({
      ...base,
      color: "#fff",
      fontSize: "13px",
      margin: 0,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menuList: (base: any) => ({
      ...base,
      border: "1px solid #829198",
      borderRadius: "9px",
      boxShadow: "none",
      backgroundColor: "#fff",
      paddingTop: 0,
      paddingBottom: 0,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: "#263238",
      color: state.isSelected ? "#7D93AD" : "#fff",
      paddingTop: "18px",
      cursor: "pointer",
      fontSize: "13px",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    placeholder: (base: any) => ({
      ...base,
      color: "#fff",
      fontStyle: "italic",
      fontSize: "13px",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: 0,
      paddingRight: 8,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menu: (base: any) => ({
      ...base,
      backgroundColor: "#fff",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    singleValue: (base: any) => ({
      ...base,
      color: "#fff",
      fontSize: "13px",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    noOptionsMessage: (base: any) => ({
      ...base,
      color: "#fff",
      fontSize: "13px",
      textAlign: "left",
      fontStyle: "italic",
    }),
  };

  const darkMediumStyles = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    container: (base: any) => ({
      ...base,
      minWidth: "50px",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (base: any) => ({
      ...base,
      boxShadow: "none",
      backgroundColor: "#263238",
      color: "#fff",
      minHeight: "44px",
      "&:hover": {
        border: "1px solid #829198",
      },
      borderRadius: 0,
      border: "1px solid #fff",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    input: (base: any) => ({
      ...base,
      color: "#fff",
      fontSize: "13px",
      //minWidth: '50px',
      margin: 0,
      borderRadius: 0,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menuList: (base: any) => ({
      ...base,
      border: "1px solid #829198",
      borderRadius: "0px",
      boxShadow: "none",
      backgroundColor: "#fff",
      paddingTop: 0,
      paddingBottom: 0,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: "#263238",
      color: state.isSelected ? "#7D93AD" : "#fff",
      paddingTop: "18px",
      cursor: "pointer",
      fontSize: "13px",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    placeholder: (base: any) => ({
      ...base,
      color: "#fff",
      fontStyle: "italic",
      fontSize: "13px",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: 0,
      paddingRight: 8,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menu: (base: any) => ({
      ...base,
      backgroundColor: "#fff",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    singleValue: (base: any) => ({
      ...base,
      color: "#fff",
      fontSize: "13px",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    noOptionsMessage: (base: any) => ({
      ...base,
      color: "#fff",
      fontSize: "13px",
      textAlign: "left",
      fontStyle: "italic",
    }),
  };

  let customStyles =
    props.type === SelectType.REGULAR ? regularStyles : darkSmallStyles;
  if (props.type === SelectType.DARKMEDIUM) customStyles = darkMediumStyles;

  if (props.control) {
    return (
      <Controller
        control={props.control}
        name={props.name}
        render={({ field, fieldState }) => {
          return (
            <>
              <Select
                components={{
                  IndicatorSeparator: () => null,
                }}
                name={props.name}
                isDisabled={props.isDisabled}
                isLoading={props.isLoading}
                styles={customStyles}
                options={props.optionsList}
                isMulti={props.isMulti}
                placeholder={props.placeHolder || ""}
                value={field.value}
                onChange={(data) => {
                  field.onChange(data);
                  if (props.onChange) {
                    props.onChange(data);
                  }
                }}
                isSearchable={props.isSearchable}
                noOptionsMessage={() => props.noOptionsMessage || ""}
              />
              <p className="text-input__error-message">
                {fieldState?.error?.message}
              </p>
            </>
          );
        }}
      />
    );
  } else {
    return (
      <>
        <Select
          components={{
            IndicatorSeparator: () => null,
          }}
          name={props.name}
          isDisabled={props.isDisabled}
          styles={customStyles}
          options={props.optionsList}
          isMulti={props.isMulti}
          placeholder={props.placeHolder || ""}
          value={props.values}
          onChange={props.onChange}
          isSearchable={props.isSearchable}
          noOptionsMessage={() => props.noOptionsMessage || ""}
        />
      </>
    );
  }
};

export default CustomSelect;
