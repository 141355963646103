import { FunctionComponent, useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../../assets/img/trigano-website.svg";
import { Button, ButtonType, OptionSelect, Searchbar, HeaderDropdown } from '_components';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { atomToken, atomUser } from "_atoms";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "react-multi-select-component";
import { useRecoilValue } from "recoil";
import { DealershipResponseType, getDealershipsById } from "_api";
import { AxiosResponse } from "axios";
import './header.scss';

type SearchFormValues = {
  search: string;
};

const Header: FunctionComponent = () => {
  const [token] = useRecoilState(atomToken);
  const { t } = useTranslation();
  const isConnected = !!token;
  const userLogged = useRecoilValue(atomUser);
  const [dealershipOptions, setDealershipOptions] = useState<Array<OptionSelect>>([]);
  const [dealershipSelectedOptions, setDealershipSelectedOptions] = useState<Array<OptionSelect>>([]);
  const navigate = useNavigate();

  const [dealersRequestLoading, setDealersRequestLoading] = useState<boolean>(false);

  const searchValidationSchema = Yup.object().shape({
    search: Yup.string().required()
  });

  const searchOnSubmit = (data: SearchFormValues) => {
    console.log(data);
  };

  const {
    control: searchControl,
    handleSubmit: searchHandleSubmit,
  } = useForm<SearchFormValues>({
    resolver: yupResolver(searchValidationSchema),
    mode: 'onChange',
    defaultValues: {
      search: ''
    }
  });

  useEffect(() => {

    // Load dealerships.
    if (userLogged && userLogged.username) {
      setDealersRequestLoading(true);
      getDealershipsById(userLogged.username).then((result: AxiosResponse<DealershipResponseType[]>) => {
        setDealersRequestLoading(false);
        const dealers = result.data.map((dealer) => {
          return {
            label: dealer.name,
            value: dealer.id.toString(),
            name: dealer.name,
          };
        })
        setDealershipOptions(dealers);
        setDealershipSelectedOptions(dealers);
      }, () => {
        setDealersRequestLoading(false);
      });
    }

  }, []);

  return (
    <header className="header">

      <div className="header__content-wrapper">
        <Link to="/configurator" className="header__logo-link">
          <Logo className="header__logo" />
        </Link>
        <div className="header__actions-wrapper">
          {isConnected ? (
            <>
              <form className="header__searchbar-wrapper" onSubmit={searchHandleSubmit(searchOnSubmit)}>
                <Searchbar placeholder={t('header_search')} control={searchControl} onSubmit={searchHandleSubmit(searchOnSubmit)} />
              </form>
              <form className="header__dealership-filter-wrapper">
                <MultiSelect
                  options={dealershipOptions}
                  valueRenderer={(selected, _options) => {
                    if (selected.length === _options.length) {
                      return t('header_dealerships');
                    }
                    else if (selected.length === 0) {
                      return t('header_no_dealership');
                    }
                    else if (selected.length > 1) {
                      return selected.length + " " + t('header_dealerships_selected');
                    } else { return selected.map(({ label }) => label + ' '); }
                  }}
                  value={dealershipSelectedOptions}
                  onChange={setDealershipSelectedOptions}
                  isLoading={dealersRequestLoading}
                  labelledBy={t('search')}
                  overrideStrings={{
                    allItemsAreSelected: t('header_dealerships_selected'),
                    clearSearch: t('header_clear_search'),
                    clearSelected: t('header_clear_selected'),
                    noOptions: t('header_no_dealer'),
                    search: t('search'),
                    selectAll: t('header_select_all_dealers'),
                    selectAllFiltered: t('header_select_all_dealers_filtered'),
                    selectSomeItems: t('header_select_dealer'),
                    create: t('created'),
                  }}
                />

              </form>
              <Button type={ButtonType.ALERT} alertsNb={9} />
              <HeaderDropdown />
            </>
          ) : (
            <Button type={ButtonType.LIGHTSMALL} text={t('login_button')} onClick={() => navigate('login')} />
          )}
        </div>

      </div>

    </header>
  );
}

export default Header;