import { FunctionComponent, useRef } from "react";
import { useModal, useOutsideClick } from "_hooks";
import './modal.scss';


export type ModalProps = {
    children: React.ReactNode;
    content?: boolean;
    oncancel?: () => void;
    onValidate?: () => void;

}

export const Modal: FunctionComponent<ModalProps> = (props) => {
    const wrapperRef = useRef(null);
    
    const { visible } = useModal();
    const { setModal } = useModal();


    useOutsideClick(wrapperRef, () => {
        setModal(false);
        if (props.oncancel) {
            props.oncancel();
        }
    });
    return (
        <div className={visible ? 'modal modal--open' : 'modal modal--close'} >
            <div className="modal__content" ref={wrapperRef}>
                {/*<button className="modal__close-button" onClick={() => { setModal(false) }}></button>*/}
                {props.children}
            </div>
        </div >
    );
}

export default Modal;