import { FunctionComponent, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Button, ButtonType } from "_components";
import { getLoginRequest, getUser } from "_api";
import { useRecoilState, useSetRecoilState } from "recoil";
import { atomToken, atomUser, atomUserLang } from "_atoms";
import { useLoading } from "_hooks";
import { ReactComponent as Logo } from "../../assets/img/trigano-login.svg";
import { User } from "_types";
import { useTranslation } from "react-i18next";
import './login-page.scss';
import atomConfigIds from "_atoms/atomConfigIds";

const LoginPage: FunctionComponent = () => {
  const { instance } = useMsal();
  const setToken = useSetRecoilState(atomToken);
  const setUser = useSetRecoilState(atomUser);
  const [user] = useRecoilState(atomUser);
  const navigate = useNavigate();
  const [token] = useRecoilState(atomToken);
  const { setLoading } = useLoading();
  const { t, i18n } = useTranslation();
  const [configIds, setConfigIds] = useRecoilState(atomConfigIds);

  const setUserLang = useSetRecoilState(atomUserLang);



  const login = () => {
    setLoading(true);

    instance.loginPopup(getLoginRequest(configIds.userMail)).then((result) => {
      setLoading(false);
      const token = result.accessToken;

      if (result.account) {
        const username = result.account.name || '';
        const name = username.substring(0, username.indexOf("@") > -1 ? username.indexOf("@") : username.length);
        const firstname = name.substring(0, name.indexOf(".") > -1 ? name.indexOf(".") : name.length);
        const lastname = name.split('.').pop();
        let displayName = firstname + ' ' + lastname;
        if (result.uniqueId == 'edb96ca5-6f18-4434-bebb-0338c9033134') { // oubliez moi
          displayName = 'Nicolas Cissa';
        }
        const user: User = {
          id: result.uniqueId,
          username: result.account?.username || '',
          name: result.account?.name || '',
          completeName: displayName,
        }
        setUser(user);
      }
      setToken(token);
      navigate('/configurator');
    }, (error) => {
      setLoading(false);
      toast.error(t('login_error'));
    });
  }


  useEffect(() => {
    if (user) {
      getUser(user.id).then((result) => {
        setLoading(false);
        if (result && result.data && result.data.length && result.data[0]) {
          const lang = result?.data[0].language ;
          const langUser = result.data[0] ?? null; 
            i18n.changeLanguage(lang);
            setUserLang(langUser);
        }
      }, (error) => {
        console.log('error: ', error);
        setLoading(false);
        toast.error(t('login_error'));
      });
    }
  }, [user, token,i18n]);

  
  return (
    <div className="login-page">
      <div className="login-page__illustration"></div>
      <div className="login-page__functionnal-wrapper">
        <div className="login-page__login-card">
          <Logo className="login-page__logo" />
          <h1 className="login-page__description">{t('login_description')}</h1>
          <div className="login-page__login-button-wrapper">
            <Button type={ButtonType.DARKSMALL} text={t('login_button')} onClick={login} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;