import { useState, FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextInput, TextArea } from "_components";
import '../AdditionalOptionItem/additional-option-item.scss';

type TakeOverOptionType = {
  description: string;
  price?: number;
};

type TakeOverOptionProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
};

const TakeOverOption: FunctionComponent<TakeOverOptionProps> = ({ control, name }) => {

  const [updateMode, setUpdateMode] = useState(true);
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const [option, setOption] = useState<TakeOverOptionType>({
          description: field.value.description,
          price: field.value.price,
        });

        return (
          <div className="additional-option">
            <div className="additional-option__title">{t('vehicle_recovery')}</div>

            <div className="additional-option-item additional-option__item__takeover">
              <TextArea
                id="description"
                name={t('description')}
                label="Description"
                placeholder={t('add_description')}
                value={option.description}
                disabled={!updateMode}
                onChange={(e) => setOption({ ...option, description: (e.target as HTMLTextAreaElement).value })}
              />
              <TextInput
                id="price"
                name="price"
                label={t('price_label')}
                type="number"
                value={option.price}
                disabled={!updateMode}
                onChange={(e) => setOption({ ...option, price: Number((e.target as HTMLInputElement).value) })}
              /> {t('price_currency')}

              {updateMode ? (
                <div className="save-btn-container">
                  <button
                    className="save-btn"
                    onClick={() => { field.onChange(option); setUpdateMode(false); }}
                    disabled={typeof option.price !== 'number'}>
                    {t('validate')}
                  </button>
                </div>
              ) : (
                <button className="action-button--update-btn" onClick={() => setUpdateMode(true)}></button>
              )}

              <button className="action-button--delete-btn" onClick={() => { setOption({ description: "", price: 0 }); setUpdateMode(true); field.onChange(option); }}></button>

            </div>
          </div>
        )
      }}
    />
  );
};

export default TakeOverOption;