import { FunctionComponent, useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import {
  TextInput,
  CustomSelect,
  SelectType,
  TextArea,
  OptionSelect,
} from "_components";
import { useTranslation } from "react-i18next";
import "../ClientInformations/client-informations.scss";
import { DealerListType } from "_api";
export type ClientInformationsType = {
  dealer: {
    email: string;
    name: string;
    phone: string;
    address: string;
    CUNO: string;
    ADID: string;
    cuno_adid: string;
  };
  client: {
    email?: string;
    firstname?: string;
    name?: string;
    phone?: string;
  };
};

type ClientInformationsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
  dealerList?: Array<DealerListType>;
};

const ClientInformations: FunctionComponent<ClientInformationsProps> = ({
  control,
  name,
  dealerList,
}) => {
  const { t } = useTranslation();

  const [dealershipOptions, setDealershipOptions] =
    useState<Array<DealerListType>>();
  const [dealershipSelectedOption, setDealershipSelectedOption] =
    useState<OptionSelect>();
  useEffect(() => {
    if (dealerList) {
      const dealers = dealerList.map((dealer) => {
        return {
          id: dealer.id,
          label: dealer.name + " - " + dealer.city || "",
          value: dealer.name || "",
          name: dealer.name || "",
          email: dealer.email || "",
          phone: dealer.phone || "",
          address:
            dealer.address + " , " + dealer.postal_code + " " + dealer.city ||
            "",
          postal_code: dealer.postal_code || "",
          city: dealer.city || "",
          CUNO: dealer.CUNO || "",
          ADID: dealer.ADID || "",
          cuno_adid: dealer.cuno_adid || "",
        };
      });
      setDealershipOptions(dealers);
    }
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const [info, setInfo] = useState<ClientInformationsType>({
          dealer: {
            email: field.value?.dealer.email,
            name: field.value?.dealer.name,
            phone: field.value?.dealer.phone,
            address: field.value?.dealer.address,
            CUNO: field.value?.dealer.CUNO,
            ADID: field.value?.dealer.ADID,
            cuno_adid: field.value.dealer.cuno_adid,
          },
          client: {
            email: field.value?.client.email,
            firstname: field.value?.client.firstname,
            name: field.value?.client.name,
            phone: field.value?.client.phone,
          },
        });

        const setInfoValue = (
          sub: "dealer" | "client",
          name: string,
          value: any
        ) => {
          setInfo((prev) => ({
            ...prev,
            [sub]: {
              ...prev[sub],
              [name]: value,
            },
          }));
        };

        const handleSubmit = () => {
          field.onChange(info);
        };

        const handleSelectChange = (dealer: DealerListType) => {
          const dealerFromApi = dealershipOptions?.filter(
            (dealerApi) => dealerApi.cuno_adid === dealer.value
          )[0];
          if (dealerFromApi) {
            console.log(dealershipOptions);
            setDealershipSelectedOption({
              label: dealerFromApi.label ?? "",
              name: dealerFromApi.name ?? "",
              value: dealerFromApi.cuno_adid ?? "",
            });
            setInfoValue("dealer", "name", dealerFromApi.name);
            setInfoValue("dealer", "email", dealerFromApi.email);
            setInfoValue("dealer", "phone", dealerFromApi.phone);
            setInfoValue("dealer", "address", dealerFromApi.address);
            setInfoValue("dealer", "CUNO", dealerFromApi.CUNO);
            setInfoValue("dealer", "ADID", dealerFromApi.ADID);
            setInfoValue("dealer", "cuno_adid", dealerFromApi.cuno_adid);
          }
        };

        return (
          <>
            <div className="configurator-form-section">
              <div className="configurator-form-section__group">
                <div className="configurator-form-section__group-label">
                  {t("distributor")} *
                </div>
                <div className="configurator-form-section__dealer-select">
                  <CustomSelect
                    name="dealerSelect"
                    optionsList={
                      dealershipOptions?.map((dealerApi) => {
                        return {
                          label: dealerApi.label,
                          value: dealerApi.cuno_adid,
                          name: dealerApi.name,
                        };
                      }) ?? []
                    }
                    placeHolder={t("choose_dealer")}
                    values={dealershipSelectedOption}
                    onChange={handleSelectChange}
                    required={true}
                    type={SelectType.DARKSMALL}
                  />
                  <TextInput
                    id="dealerName"
                    type="text"
                    name={`${name}.dealer.name`}
                    label={t("profile_lastname")}
                    value={info.dealer.name}
                    onChange={(e: any) => {
                      setInfoValue("dealer", "name", e.target.value);
                    }}
                  />
                  <TextInput
                    id="dealerEmail"
                    type="text"
                    name={`${name}.dealer.email`}
                    label={t("profile_email")}
                    value={info.dealer.email}
                    onChange={(e: any) => {
                      setInfoValue("dealer", "email", e.target.value);
                    }}
                  />
                  <TextInput
                    id="dealerPhone"
                    type="text"
                    name={`${name}.dealer.phone`}
                    label={t("profile_phone")}
                    value={
                      info.dealer.phone === "0000000000"
                        ? ""
                        : info.dealer.phone
                    }
                    onChange={(e: any) => {
                      setInfoValue("dealer", "phone", e.target.value);
                    }}
                  />
                  <TextArea
                    id="dealerAddress"
                    name={`${name}.dealer.address`}
                    label={t("address")}
                    value={info.dealer.address}
                    onChange={(e: any) => {
                      setInfoValue("dealer", "address", e.target.value);
                    }}
                  />

                  <div className="save-btn-container">
                    <button
                      onClick={handleSubmit}
                      className="save-btn"
                      disabled={
                        !info.dealer.name ||
                        !info.dealer.email ||
                        !info.dealer.phone ||
                        !info.dealer.address
                      }
                    >
                      {t("validate")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

export default ClientInformations;
